<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
        class="text-center"
      >
        <h1>{{ $t('404.pageTitle' ) }}</h1>
        <p>{{ $t('404.text' ) }}</p>
        <v-btn
          :to="{ name: 'affairs' }"
          tile
          color="primary"
        >
          {{ $t('404.back') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  metaInfo () {
    return {
      title: this.$t('404.pageTitle'),
    }
  },
}
</script>
